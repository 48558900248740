import React, { Component } from 'react';
import ScheduleDemoButton from '../scheduleDemo/ScheduleDemoButton';
import info from '../../../static/images/info-icon-2.svg'

export default class PriceTable extends Component {
    state = {
        categories: [
            {
                category_name: 'ENGAGEMENT',
                features: [
                    { feature_name: "Amber's Reachouts", starter: 'Lifecycle OR Pulse', pro: 'Lifecycle and Pulse', advanced: 'Lifecycle and Pulse', text: true, tooltip: 'Lifecycle: Amber reaches out to your employees<br/> based on fixed milestones during their lifecycle.<br/> For eg. 30 day milestone, 90 day milestone,<br/> 1 year work anniversary and more. <br/><br/> Pulse: Amber reaches out to your employees<br/> with ready-made question templates such as<br/> Diversity, Equity and Inclusion and  Employee <br/>Wellbeing Check, or DIY questions, when you<br/>  want her to.Think pulse surveys but more human!' },
                    { feature_name: 'Anonymous Bat', starter: true, pro: true, advanced: true, text: false, tooltip: "Deploy Anonymous Bat, Amber's digital colleague,<br/> who is available 24x7 as your ombudsperson to handle<br/> highly sensitive issues discreetly" },
                    { feature_name: 'Multiple Languages', starter: false, pro: true, advanced: true, text: false, tooltip: '' },
                ]
            },
            {
                category_name: 'ANALYTICS',
                features: [
                    { feature_name: 'Turnover Intent - PTM', starter: true, pro: true, advanced: true, text: false, tooltip: 'Identify your at-risk employees and <br/> understand the reason behind their disengagement' },
                    { feature_name: 'Daily Insights', starter: true, pro: true, advanced: true, text: false, tooltip: 'Get people insights pushed daily to your inbox' },
                    { feature_name: 'Advanced Filters', starter: '5', pro: true, advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Heat Maps', starter: '5', pro: true, advanced: true, text: true, tooltip: '' },
                    { feature_name: 'TextSens (Powered by in-house NLP engine)', starter: 'Limited', pro: true, advanced: true, text: true, tooltip: "inFeedo's proprietary AI-based algorithm provides <br/>an in-depth textual analysis of employees responses" },
                    { feature_name: 'Manager & Executive Dashboards', starter: 'Executive only', pro: true, advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Employee Watchlist(s)', starter: '1', pro: '3', advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Custom Data Requests', starter: false, pro: false, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Advanced Analytics', starter: false, pro: false, advanced: true, coming_soon: true, text: false, tooltip: '' },
                ]
            },
            {
                category_name: 'PLATFORM',
                features: [

                    { feature_name: 'Automated 1:1 Meeting Scheduling', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Documentation and Action Tracking', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Export Raw Data', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Self Service User Access Management', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Single Sign On (SSO)', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Communication Channels', starter: 'Email + Slack + Whatsapp', pro: 'Email + Slack + Whatsapp + Custom ($)', advanced: true, text: true, tooltip: '' },
                    { feature_name: 'HRMS Integration', starter: 'Basic', pro: 'Basic + Custom ($)', advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Employee Cohorts', starter: '2', pro: '5', advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Multiple Views', starter: false, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Save Views', starter: false, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'SFTP Data Transfer', starter: false, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Sensitive Word Alert', starter: false, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'API', starter: false, pro: false, advanced: true, coming_soon: true, text: false, tooltip: '' },
                    { feature_name: 'Group Company Account', starter: false, pro: false, advanced: true, coming_soon: true, text: false, tooltip: '' },
                ]
            },
            {
                category_name: 'SUPPORT',
                features: [
                    { feature_name: 'Project Planning & Review', starter: 'Documentation', pro: 'Onboarding Manager', advanced: 'Sr. Onboarding Manager', text: true, tooltip: '' },
                    { feature_name: 'Best Practice Implementation', starter: 'Documentation', pro: '1 Pre Survey', advanced: '1 Pre Survey', text: true, tooltip: '' },
                    { feature_name: 'Question Bank Consultation', starter: 'Documentation', pro: '2 + Extra ($)', advanced: '5 + Extra ($)', text: true, tooltip: "Consult with our People Science team to ensure Amber's<br/> question bank suits your organization's mission, vision and values" },
                    { feature_name: 'Action Planning & Consulting', starter: 'Documentation', pro: 'Quarterly Review', advanced: 'Monthly Review', text: true, tooltip: '' },
                    { feature_name: 'Template & Framework Upgrades', starter: true, pro: true, advanced: true, text: false, tooltip: '' },
                    { feature_name: 'Product Training', starter: 'Webinar + Videos', pro: '2 personalized per month', advanced: '4 personalized per month', text: true, tooltip: '' },
                    { feature_name: 'Support', starter: 'Email + Chat', pro: 'Email + Chat', advanced: 'Email + Chat', text: true, tooltip: '' },
                    { feature_name: 'Post Survey Question Bank Changes', starter: false, pro: 'Once every 6 months', advanced: 'Once every 3 months', text: true, tooltip: '' },
                    { feature_name: 'Customer Success Manager', starter: false, pro: 'Dedicated CSM', advanced: 'Dedicated Sr. CSM', text: true, tooltip: '' },
                    { feature_name: 'Advanced Reporting with People Science', starter: false, pro: '$', advanced: true, text: true, tooltip: '' },
                    { feature_name: 'Demographic based Questionnaire changes', starter: false, pro: false, advanced: true, text: false, tooltip: '' },
                ]
            },
        ]
    }
    render() {
        const { categories } = this.state;
        return (
            <div className="has-text-centered">
                <div className="pd-10">
                    <br />
                    <br />
                    <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-10">No matter what your needs, we have the plan for you.</h3>
                    <br />
                    <br />
                    <div id="stickyTableHead" className="sticky columns column is-11 mar-auto no-margin-padding-bottom is-hidden-mobile">
                        <table className="table is-fullwidth price-card">
                            <thead>
                                <tr className="plan-row is-hidden-mobile">
                                    <th className="td-width-1"></th>
                                    <th className="td-width-2">
                                        <p className="is-gold mg-bottom-5">STARTER</p>
                                        <ScheduleDemoButton is_small={true} button_text={'CONTACT US'} />
                                    </th>
                                    <th className="td-width-3">
                                        <p className="is-sky mg-bottom-5">PRO</p>
                                        <ScheduleDemoButton is_small={true} button_text={'CONTACT US'} />
                                    </th>
                                    <th className="td-width-4-2">
                                        <p className="is-red mg-bottom-5">ADVANCED</p>
                                        <ScheduleDemoButton is_small={true} button_text={'CONTACT US'} />
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="responsive-table columns column is-11 mar-auto flex-wrap">
                        <div className="column is-12 is-hidden-tablet mg-bottom-20 is-paddingless">
                            <div className="price-mobile">
                                <p className="is-gold mg-bottom-5 has-text-weight-bold"> <span className="bg-gold mg-right-10 price-mobile-box"></span>STARTER</p>
                            </div>
                            <div className="price-mobile">
                                <p className="is-sky mg-bottom-5 has-text-weight-bold"><span className="bg-sky mg-right-10 price-mobile-box"></span>PRO</p>
                            </div>
                            <div className="price-mobile">
                                <p className="is-red mg-bottom-5 has-text-weight-bold"><span className="bg-red mg-right-10 price-mobile-box"></span>ADVANCED</p>
                            </div><br /><br />
                        </div>
                        <table className="table is-fullwidth price-card">
                            <tbody>
                                {categories.map(category => (
                                    <React.Fragment>
                                        <tr key={category.category_name} className="heading-row">
                                            <td>
                                                {category.category_name}
                                            </td>
                                            <td className="is-hidden-mobile"></td>
                                            <td className="is-hidden-mobile"></td>
                                            <td className="is-hidden-mobile"></td>
                                        </tr>
                                        {category.features.map(feature => (
                                            <tr key={feature.feature_name} className="feature-row">
                                                <td className="td-width-1">
                                                    {feature.coming_soon
                                                        ? <span className="has-text-grey"><i><span className="soon">Coming Soon - </span></i> {feature.feature_name}</span>
                                                        : <span>{feature.feature_name} {feature.tooltip ? <span className="is-hidden-mobile pd-top-5" data-tip={`<p className="has-text-left">${feature.tooltip}</p>`}>&nbsp;<img src={info} width="15px" alt="info" className="info-icon" /></span> : null}<br className="is-hidden-tablet" /></span>
                                                    }
                                                    <span className="is-pulled-right is-hidden-tablet mg-4 ">
                                                        {feature.advanced === true ? <span className="bg-red"></span> : null}
                                                        {feature.advanced === false || feature.advanced === '' ? <span className="dash">&mdash;</span> : null}
                                                        {feature.advanced.length ? <span className="font-12 is-red">{feature.advanced.includes('$') ? <span>{feature.advanced}</span> : <span className="has-text-weight-bold">{feature.advanced}</span>}</span> : null}
                                                    </span>
                                                    <span className="is-pulled-right is-hidden-tablet mg-4 ">
                                                        {feature.pro === true ? <span className="bg-sky"></span> : null}
                                                        {feature.pro === false || feature.pro === '' ? <span className="dash">&mdash;</span> : null}
                                                        {feature.pro.length ? <span className="font-12 is-sky">{feature.pro.includes('$') ? <span>{feature.pro}</span> : <span className="has-text-weight-bold">{feature.pro}</span>}</span> : null}
                                                    </span>
                                                    <span className="is-pulled-right is-hidden-tablet mg-4">
                                                        {feature.starter === true ? <span className="bg-gold"></span> : null}
                                                        {feature.starter === false || feature.starter === '' ? <span className="dash">&mdash;</span> : null}
                                                        {feature.starter.length ? <span className="font-12 is-gold">{feature.starter.includes('$') ? <span>{feature.starter}</span> : <span className="has-text-weight-bold">{feature.starter}</span>}</span> : null}
                                                    </span>
                                                </td>
                                                <td className="has-text-centered is-hidden-mobile td-width-2">
                                                    {feature.starter === true ? <span className="bg-gold"></span> : null}
                                                    {feature.starter === false || feature.starter === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.starter.length ? <span className="font-14">{feature.starter}</span> : null}
                                                </td>
                                                <td className="has-text-centered is-hidden-mobile td-width-3">
                                                    {feature.pro === true ? <span className="bg-sky"></span> : null}
                                                    {feature.pro === false || feature.pro === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.pro.length ? <span className="font-14">{feature.pro}</span> : null}
                                                </td>
                                                <td className="has-text-centered is-hidden-mobile td-width-4">
                                                    {feature.advanced === true ? <span className="bg-red"></span> : null}
                                                    {feature.advanced === false || feature.advanced === '' ? <span className="dash">&mdash;</span> : null}
                                                    {feature.advanced.length ? <span className="font-14">{feature.advanced}</span> : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}


